import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    meta: { title: '首页'},
    children:[{
      path: '',
      name: 'Index',
      component: () => import('@/views/components/Index/index.vue'),
      meta: { title: '首页'}
    },
    {
      path: '/zoujinchengfa',
      name: 'zoujinchengfa',
      component: () => import( '@/views/components/ZouJinChengFa/index.vue'),
      meta: { title: '走进城发'}
    },{
      path: '/newscenter',
      name: 'newscenter',
      component: () => import( '@/views/components/NewCenter/index.vue'),
      meta: { title: '新闻中心'}
    },{
      path: '/newsdetail',
      name: 'newsdetail',
      component: () => import( '@/views/components/NewDetail/index.vue'),
      meta: { title: '新闻中心详情 '}
    },{
      path: '/business',
      name: 'businessoperations',
      component: () => import( '@/views/components/BusinessOperations/index.vue'),
      meta: { title: '业务经营'}
    },{
      path: '/buydetail/:id',
      name: 'buydetail',
      component: () => import( '@/views/components/BuyDetail/index.vue'),
      meta: { title: '采购业务详情 '}
    },{
      path: '/partyconstruction',
      name: 'partyconstruction',
      component: () => import( '@/views/components/PartyConstruction/index.vue'),
      meta: { title: '党建工作'}
    },{
      path: '/partydetail/',
      name: 'partydetail',
      component: () => import( '@/views/components/PartyDetail/index.vue'),
      meta: { title: '党建工作详情 '}
    },{
      path: '/joinus',
      name: 'joinus',
      component: () => import( '@/views/components/JoinUs/index.vue'),
      meta: { title: '加入我们'}
    },{
      path: '/companys',
      name: 'companys',
      component: () => import( '@/views/components/Companys/index.vue'),
      meta: { title: '加入我们'}
    }]
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
