import axios from 'axios';
// 创建axios实例
const service = axios.create({
    // baseURL:'http://10.88.11.195:9091/home'
    // baseURL:'http://47.102.130.48:8020/',
    baseURL:'https://www.gycfjt.com/',
    // baseURL:'http://localhost:8082/'
});

// request拦截器
service.interceptors.request.use(config => {
    // config.headers['token'] = '8e3fe18a-62ac-4f5f-8742-130455f3ec7c'
    return config;
}, error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
});

// respone拦截器
service.interceptors.response.use(
    response => {
        return response.data
    }, error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)
export default service;