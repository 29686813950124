<template>
  <div class="home">
    <Dtheader></Dtheader>
    <DTbanner v-show="showBanner"></DTbanner>
    <!-- 路由匹配到的组件将显示在这里 -->
    <div class="view-c">
        <router-view></router-view>
    </div>
    <DTfooter></DTfooter>
  </div>
</template>

<script>
import Dtheader from "@/components/DTheader";
import DTfooter from "@/components/DTfooter";
import DTbanner from "@/components/DTbanner";
export default {
  name: "Home",
  components: {Dtheader,DTfooter,DTbanner},
  data() {
    return {
      showBanner:true
    };
  },
  watch: {
    '$route'(to, from) {
      let path = to.path;
      if(path == '/newsdetail'){
        this.showBanner = false
      }else{
        this.showBanner = true
      }
    }
  },
  methods: {
    checkRouter(){
      let path = this.$route.path;
      if(path == '/newsdetail'){
        this.showBanner = false
      }
    }
  },
  created() {},
  mounted() {
    this.checkRouter();
  },
};
</script>
<style scoped lang="scss">
.home {
  width: 100vw;
  overflow-x: hidden;
}

</style>
