import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css' // 引入样式
import 'quill/dist/quill.snow.css' // snow theme
import 'quill/dist/quill.bubble.css' // bubble theme

Vue.use(VueQuillEditor, /* { default global options } */)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import './style/index.scss'

Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.directive("drag", {
  bind(el) {
    el.onmousedown = function (event) {
      let isDragging=false;
      var event = event || window.event;  //兼容IE浏览器
      // if(event instanceof Event){
      event.preventDefault();
      event.stopPropagation();
      // }
      //   鼠标点击物体那一刻相对于物体左侧边框的距离=点击时的位置相对于浏览器最左边的距离-物体左边框相对于浏览器最左边的距离
      var diffX = event.clientX - el.offsetLeft;
      var diffY = event.clientY - el.offsetTop;
      let startX = event.clientX;
      let startY = event.clientY;
      if (typeof el.setCapture !== 'undefined') {
        el.setCapture();
      }
      document.body.onmousemove = function (event) {
        event.preventDefault();
        event.stopPropagation();
        document.body.style.cursor = 'move';
        var event = event || window.event;
        var moveX = event.clientX - diffX;
        var moveY = event.clientY - diffY;

        let deltaX = Math.abs(event.clientX - startX);
        let deltaY = Math.abs(event.clientY - startY);
        if (deltaX > 5 || deltaY > 5) {
          isDragging = true;
        }

        if (moveX < 0) {
          moveX = 0
        } else if (moveX > window.innerWidth - el.offsetWidth) {
          moveX = window.innerWidth - el.offsetWidth
        }
        if (moveY < 0) {
          moveY = 0
        } else if (moveY > window.innerHeight - el.offsetHeight) {
          moveY = window.innerHeight - el.offsetHeight
        }
       
        el.style.left = moveX + 'px';
        el.style.top = moveY + 'px'
      }
      el.onclick=function(event){
        if (!isDragging) {
          // 在这里处理DOM自身的点击事件
          window.open("http://58.223.177.10:13000/portal", "_blank");
        }
      }
      document.body.onmouseup = function (event) {
        document.body.style.cursor = 'default';
        this.onmousemove = null;
        this.onmouseup = null;
        //修复低版本ie bug
        if (typeof el.releaseCapture != 'undefined') {
          el.releaseCapture();
        }
      }
    }
  }
});


Vue.directive('stopDrag', {
  bind(el) {
    let element = el;
    element.onmousedown = function (e) {
      e.stopPropagation()
    }
  }
})
Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression]();
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
