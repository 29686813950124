<template>
  <div class="public-header">
    <div style="display: flex; align-items: center">
      <div class="header-logo">
        <img src="@/assets/index/logo.jpg" alt="" />
      </div>
      <div class="header-title">赣榆城市建设发展集团</div>
    </div>
    <div>
      <el-menu
        :default-active="activeIndex"
        text-color="#000"
        active-text-color="#969696"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item index="/">首页</el-menu-item>
        <el-submenu
          index="2"
          @click.native="goRouter({ value: '2', url: '/zoujinchengfa' })"
        >
          <template slot="title">走进城发</template>
          <el-menu-item index="/zoujinchengfa" v-show="false"
            >走进城发</el-menu-item
          >
          <el-menu-item index="/zoujinchengfa?type=1">集团简介</el-menu-item>
          <el-menu-item index="/zoujinchengfa?type=2">组织架构</el-menu-item>
          <el-menu-item index="/zoujinchengfa?type=3">党建工作</el-menu-item>
          <el-menu-item index="/zoujinchengfa?type=4">企业文化</el-menu-item>
          <el-menu-item index="/zoujinchengfa?type=5">荣誉墙</el-menu-item>
          <el-menu-item index="/zoujinchengfa?type=6">发展历程</el-menu-item>
        </el-submenu>
        <el-submenu
          index="3"
          @click.native="goRouter({ value: '3', url: '/partyconstruction' })"
        >
          <template slot="title">集团产业</template>
          <el-menu-item index="/partyconstruction" v-show="false"
            >集团产业</el-menu-item
          >
          <el-submenu
            v-for="(company, i) in companys"
            :key="i"
            :index="company.name"
          >
            <template slot="title">{{ company.name }}</template>
            <el-menu-item
              v-for="(item, i2) in company.children"
              :key="i2"
              @click="go2Com(item.label, item.id,item.companyCode)"
            >
              {{ item.name }}
            </el-menu-item>
          </el-submenu>
          <!-- <el-submenu index="3-1">
              <template slot="title">公用事业</template>
              <el-menu-item index="/partyconstruction?type=1-gysy">连云港市城发公用事业集团有限公司</el-menu-item>
            </el-submenu>
            <el-submenu index="3-2">
              <template slot="title">市政工程</template>
              <el-menu-item index="/partyconstruction?type=2-szgc"><template slot="title">连云港市赣榆城发市政工程集团有限公司</template></el-menu-item>
            </el-submenu>
            <el-submenu index="3-3">
              <template slot="title">商业运营</template>
              <el-menu-item index="/partyconstruction?type=3-syyy">连云港市城发商业运营集团有限公司</el-menu-item>
            </el-submenu>
            <el-submenu index="3-4">
              <template slot="title">产业投资</template>
              <el-menu-item index="/partyconstruction?type=4-cytz">连云港市城发产业投资集团有限公司</el-menu-item>
            </el-submenu>
            <el-submenu index="3-5">
              <template slot="title">人才发展</template>
              <el-menu-item index="/partyconstruction?type=5-rcfz">连云港市赣榆区人才发展有限公司</el-menu-item>
            </el-submenu> -->
        </el-submenu>
        <el-submenu
          index="4"
          @click.native="goRouter({ value: '4', url: '/newscenter' })"
        >
          <template slot="title">新闻中心</template>
          <el-menu-item index="/newscenter" v-show="false"
            >新闻中心</el-menu-item
          >
          <el-menu-item index="/newscenter?type=1">最新发布</el-menu-item>
          <el-menu-item index="/newscenter?type=2">集团动态</el-menu-item>
          <el-menu-item index="/newscenter?type=3">子公司新闻</el-menu-item>
          <el-menu-item index="/newscenter?type=4">信息公告</el-menu-item>
          <el-menu-item index="/newscenter?type=5">招租公告</el-menu-item>
          <el-menu-item index="/newscenter?type=6">媒体聚焦</el-menu-item>
        </el-submenu>
        <el-menu-item index="/joinus">联系我们</el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { getAllCompanys } from "../../js/api/index";
export default {
  name: "DTheader",
  data() {
    return {
      activeIndex: "/",
      companys: [],
    };
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
      let path = to.path;
      let params = to.query; // 获取当前路由的查询参数对象
      if (params.type) {
        this.activeIndex = path + "?type=" + params.type;
      } else {
        this.activeIndex = path;
      }
    },
  },
  methods: {
    go2Com(label, id,code) {
      // console.log(label, id);
      this.$router.push({
        path: "partyconstruction",
        query: { label: label, id: id ,code:code},
      });
    },
    handleSelect(key) {
      if (key) {
        const url = key.split("?")[0]; // 提取参数值
        const params = key.split("=")[1]; // 提取参数值
        if (this.$route.path !== url) {
          this.$router.push({ path: url, query: { type: params } }); // 跳转并传递参数
        } else {
          let routeParams = this.$route.query.type;
          if (routeParams == params) return;
          this.$router.push({ path: url, query: { type: params } }); // 跳转并传递参数
        }
      }
    },
    goRouter(data) {
      this.activeIndex = data.url;
      let routeParams = this.$route.query.type;
      if (this.$route.path !== data.url || routeParams) {
        this.$router.push({ path: data.url }); // 跳转并传递参数
      }
    },
    checkRouter() {
      let path = this.$route.path;
      let params = this.$route.query; // 获取当前路由的查询参数对象
      if (params.type) {
        this.activeIndex = path + "?type=" + params.type;
      } else {
        this.activeIndex = path;
      }
    },
  },
  created() {
    this.checkRouter();
    getAllCompanys().then((res) => {
      // console.log("=============", res);
      this.companys = res.data;
    });
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style scoped lang="scss">
.public-header {
  padding: 0 40px;
  margin: 0 auto;
  height: 75px;
}
.header-logo {
  width: 40px;
  height: 40px;
  background: #ffffff;
  > img {
    width: 100%;
    height: 100%;
  }
}
.header-title {
  font-size: 20px;
  font-family: DouYuFun;
  color: #000;
  margin-left: 10px;
  // padding-top: 10px;
}
ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.public-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1240px;
  overflow: visible !important;
}
.public-header > .zt-navi {
  position: absolute;
  right: 11vw;
  top: 55%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-around;
  width: 40vw;
  font-size: 14px;
}

.public-header > .zt-navi > li {
  text-align: center;
  margin: 0;
  float: left;
  position: relative;
}

.public-header > .zt-navi > li > a {
  color: #000;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  padding-bottom: 3px;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: white; /* 边的颜色 */
    transition: width 0.3s ease-in-out; /* 定义动画过渡 */
  }

  &:hover::before {
    width: 100%; /* 鼠标悬停时宽度变为 100%，即从左到右展开 */
  }
}
.zt-navi > li.active > a {
  &::before {
    width: 100%;
  }
}
::v-deep .el-menu.el-menu--horizontal {
  border-bottom: none !important;
}
::v-deep .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none !important;
}
::v-deep .el-menu--horizontal > .el-menu-item {
  padding: 0 20px;
}
::v-deep .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: none !important;
  transition: none;
}
</style>
