import request from './request';
/**新闻相关接口 */
//新闻和通知列表
export function getNewsNoticeAll(data) {
    return request({
        url: '/api/newsNotice/list/'+data.type+'/'+data.pageSize+'/'+data.pageNum,
        method: 'post',
        // data
    })
}
//新闻和通知详情
export function getNewsNoticeDetail(id) {
    return request({
        url: '/api/newsNotice/'+id,
        method: 'post',
    })
}
//新闻和通知上一条下一条
export function getNewsNoticeBeforeAndAfter(data) {
    return request({
        url: 'api/newsNotice/getBeforeAndAfter/'+data.id+'/'+data.newsType,
        method: 'post',
    })
}

/**业务经营*/
//业务经营列表
export function getBusinessAll(data) {
    return request({
        url: '/api/business/list/'+data.pageSize+'/'+data.pageNum,
        method: 'post',
        // params
    })
}
//业务经营详情
export function getBusinessDetail(businessId) {
    return request({
        url: '/api/business/'+businessId,
        method: 'post',
    })
}
//业务经营上一条下一条
export function getBusinessBeforeAndAfter(businessId) {
    return request({
        url: 'api/business/getBeforeAndAfter/'+businessId,
        method: 'post',
    })
}

/**采购信息*/
//采购信息列表
export function getProCureAll(data) {
    return request({
        url: '/api/procure/list/'+data.pageSize+'/'+data.pageNum,
        method: 'post',
        // params
    })
}
//采购信息详情
export function getProCureDetail(procureId) {
    return request({
        url: '/api/procure/'+procureId,
        method: 'post',
    })
}
//采购信息上一条下一条
export function getProCureBeforeAndAfter(procureId) {
    return request({
        url: 'api/procure/getBeforeAndAfter/'+procureId,
        method: 'post',
    })
}

/**党建工作列表 
 * 类型 1:党建工作 2：员工之家 3：青年之窗
*/
export function getPartyAll(data) {
    return request({
        url: '/api/party/list/'+1+'/'+data.pageSize+'/'+data.pageNum,
        method: 'post',
    })
}
//党建工作详情
export function getPartyDetail(partyId) {
    return request({
        url: '/api/party/'+partyId,
        method: 'post',
    })
}
//党建工作上一条下一条
export function getPartyBeforeAndAfter(partyId) {
    return request({
        url: 'api/party/getBeforeAndAfter/'+partyId,
        method: 'post',
    })
}

/**学习资料 */
export function getKnowledgeAll(data) {
    return request({
        url: '/api/knowledge/list/'+data.pageSize+'/'+data.pageNum,
        method: 'post',
        // params
    })
}

/**招贤纳士 */
export function getJobAll(data) {
    return request({
        url: '/api/job/list/'+data.pageSize+'/'+data.pageNum,
        method: 'post',
        // params
    })
}
//招聘详情
export function getJobDetail(partyId) {
    return request({
        url: '/api/job/'+partyId,
        method: 'post',
    })
}

//联系我们和集团常量信息配置
export function getCommonInfo() {
    return request({
        url: '/api/constant/list/1/1',
        method: 'post',
    })
}

//人才理念
export function getPersonInfo() {
    return request({
        url: '/api/person/list/3/1',
        method: 'post',
    })
}

//发展历程
export function getHistoryInfo() {
    return request({
        url: '/api/history/list',
        method: 'post',
    })
}

export function getCompanyByCode(code) {
    return request({
        url: '/api/company/getInfoByCompanyCode/'+code,
        method: 'post',
    })
}

export function getCompanyById(id) {
    return request({
        url: '/api/company/getInfoById/'+id,
        method: 'get',
    })
}
export function getAllPartners() {
    return request({
        url: '/api/partner/list',
        method: 'post',
    })
}

export function getAllPrimes() {
    return request({
        url: '/api/primes/list',
        method: 'post',
    })
}

export function getAllLeaders() {
    return request({
        url: '/api/leaders/list',
        method: 'post',
    })
}
export function getAllCompanys() {
    return request({
        url: '/api/company/getAllCompany',
        method: 'get',
    })
}

// 获取所有轮播图
export function getAllCaousel() {
    return request({
        url: '/api/carousel/list',
        method: 'get',
    })
}
