<template>
  <div id="public_footer">
    <div class="public_footer_container">
      <div class="footer-left">
        <div style="text-align: center">
          <img style="width: 75px" src="@/assets/index/logo.png" alt="" />
          <div
            style="
              font-size: 24px;
              font-weight: bold;
              font-family: DouYuFun;
              margin-top: 10px;
            "
          >
            城发集团
          </div>
        </div>
        <ul class="contact_us">
          <li style="font-size: 12px; margin-top: 5px">
            地址：连云港市赣榆区青口镇新城区和安大厦
          </li>
          <li style="font-size: 12px; margin-top: 5px">邮编：220504</li>
          <li style="font-size: 12px; margin-top: 5px">电话：0518-86031860</li>
        </ul>
        <div
          style="
            color: #666666;
            font-size: 12px;
            margin-top: 15px;
            text-align: left;
          "
        >
          苏ICP备19002421号
        </div>
      </div>
      <div class="footer-right">
        <div style="font-size: 16px; font-weight: bold; text-align: left">
          友情链接
        </div>
        <ul class="link_list">
          <!-- <li v-for="(partner,i) in partners" :key="i">
            <a :href="partner.homepage" target="_blank"><img :src="partner.logo" alt="" /></a>
          </li> -->
          <li v-for="(partner,i) in partners" :key="i">
            <a :href="partner.homepage" target="_blank"> {{partner.companyName}}  </a>
          </li>
          <!-- <li>
            <a href=""><img src="@/assets/index/logo.png" alt="" /></a>
          </li>
          <li>
            <a href=""><img src="@/assets/index/logo.png" alt="" /></a>
          </li>
          <li>
            <a href=""><img src="@/assets/index/logo.png" alt="" /></a>
          </li>
          <li>
            <a href=""><img src="@/assets/index/logo.png" alt="" /></a>
          </li>
          <li>
            <a href=""><img src="@/assets/index/logo.png" alt="" /></a>
          </li>
          <li>
            <a href=""><img src="@/assets/index/logo.png" alt="" /></a>
          </li>
          <li>
            <a href=""><img src="@/assets/index/logo.png" alt="" /></a>
          </li>
          <li>
            <a href=""><img src="@/assets/index/logo.png" alt="" /></a>
          </li> -->
        </ul>
      </div>
    </div>
    <!-- <div class="copyright" style="">
      <span>赣榆城市建设发展集团有限公司 版权所有 Copyright ©2020.All Rights Reserved
        <a class="bei" href="http://beian.miit.gov.cn/" target="_blank" rel="nofollow">苏ICP备19002421号</a></span>
    </div> -->
  </div>
</template>
<script>
import  eventBus  from "../../js/eventBus";
import  {getAllPartners}  from "../../js/api/index";
export default {
  name: "DTfooter",
  data() {
    return {
      partners: [],
    };
  },
  methods: {
    goRouter(data) {
      eventBus.emit("currentChange", data.value);
      let url = data.url;
      if (this.$route.path !== url) {
        this.$router.push(url);
      }
    },
    getAllPartner() {
      getAllPartners().then((res) => {
        console.log(res);
        this.partners = res.data
      })
    },
  },
  created() {
    this.getAllPartner();
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
#public_footer {
  background-color: #f7f7f7;
  overflow: hidden;
}

#public_footer > .public_footer_container {
  margin: 0px auto;
  padding: 0px;
  height: 340px;
  width: 1280px;
  position: relative;
}

.footer-left {
  position: absolute;
  top: 70px;
}

.copyright {
  font-size: 0.7vw;
  color: #fff;
  text-align: center;
  padding: 15px;
}

.copyright a {
  color: #fff !important;
  text-decoration: none;
}

.copyright a:hover {
  color: #aaa !important;
  text-decoration: none !important;
}

.contact_us {
  margin-top: 15px;
  text-align: left;
}
.footer-right {
  position: absolute;
  top: 70px;
  right: 0;
  width: 75%;
  .link_list {
    margin-top: 10px;
    li {
      display: inline-block;
      float: left;
      margin-bottom: 10px;
      padding-right: 0px;
      width: 50%;
      max-height: 60px;
      > a {
        position: relative;
        display: inline-block;
        max-width: 100%;
        max-height: 60px;
        img {
          float: left;
          display: block;
          max-width: 100%;
          max-height: 60px;
        }
      }
    }
  }
}
@media screen and (max-width: 960px) {
  .copyright {
    font-size: 10px !important;
  }

  .contact_us {
    margin-top: 20px;
    text-align: left;
  }
}
</style>
